import React, {useContext} from "react";
import moment from "moment-mini";
import {mfaContext} from "..";

import {Button} from "react-bootstrap";
import OtpInput from "../../../otp-input";



const MFAEmailPanel = (props) => {
  const {loading, DataHelper, userData, handleOtpSubmit, setOtp, setOtpError, otp, inputFocus, setInputFocus, otpError, requestCodeVerified, retryEnabled, handleRetry, retryTimer, setMfaMethod, stopPolling, setRetryTimer, setShowMfa} = useContext(mfaContext);

  return (
    <div className="mfa-panel">
      <p className="mfa-heading pb-3">{"Enter your code"}</p>
      <p className="mfa-sub-heading text-justify">{loading ? <i className="fad fa-spinner"/> :
        `An email has been sent to your registered email address that ends with ${DataHelper.characterHide(userData?.email)}`}
      </p>
      <p className="mfa-sub-heading text-justify">{loading ? <i className="fad fa-spinner"/> :
        "Please click the approval link provided or enter the code below to continue."}
      </p>
      {loading ? <i className="fad fa-spinner"/> : (
        <form
          className="manual-otp-form"
          onSubmit={e => {
            e.preventDefault();
            handleOtpSubmit(otp.join(""));
          }}
        >
          <OtpInput
            count={6}
            value={otp}
            onChange={value => {
              setOtp(value);
              setOtpError(null);
            }}
            inputFocus={inputFocus}
            setInputFocus={setInputFocus}
          />
          {otpError && <div className="mfa-otp-input-error">{otpError}</div>}
        </form>
      )}
      <div className="mfa-actions-container">
        {!requestCodeVerified && retryEnabled && <div className="retry-container">
          <div className="retry-info">{"Request code expired"}</div>
          <Button
            variant="login"
            onClick={handleRetry}
          >
            {"Retry"}
          </Button>
        </div>}
        {!requestCodeVerified && !retryEnabled && retryTimer > 0 && <div className="retry-timer">
          {`expires in ${retryTimer > 60 ? moment.utc(retryTimer * 1000).format("mm:ss") : `${retryTimer} ${retryTimer > 1 ? "seconds" : "second"}`}`}
        </div>}
      </div>
      <div className="mfa-other-options-container">
        <span
          onClick={() => {
            setMfaMethod(null);
            stopPolling();
            setRetryTimer(0);
          }}
        >
          {"Use another method to receive the code"}
        </span>
        <span
          onClick={() => {
            setShowMfa(false);
            setMfaMethod(null);
          }}
        >{"Sign in to a different account"}</span>
      </div>
    </div>
  );
};

export default MFAEmailPanel;
