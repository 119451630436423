import React, {useRef, useState, useEffect} from "react";
import {Link} from "gatsby";
import {Row, Col, Container, Button, Form} from "react-bootstrap";
import Formsy from "formsy-react";
import TextField from "../components/bootstrap/input";
import {loginMutation} from "../components/logic/user";
import {useMutation} from "@apollo/client";
import {fetch, localStorage, window} from "window-or-global";
import {useAsyncSetState} from "use-async-setstate";
import {useQueryParam, StringParam} from "use-query-params";
import Terms from "../components/portal/terms";
import PrivacyPolicy from "../components/portal/privacy-policy";
import ForgotPassword from "../components/forgot-password/index";

export default function LoginControl(props) {
  let dst;
  const {authUrl, portalUrl, apiUrl, setShowMfa, setAllowedOptions, setUserData, setMfa} = props;
  const formRef = useRef(null);
  const [isChecked, setIsChecked] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [processing, setProcessing] = useAsyncSetState(false);
  const [loginError, setLoginError] = useAsyncSetState(false);
  const [authDetails, setAuthDetails] = useAsyncSetState(false);
  const [showTerm, setTerm] = useAsyncSetState(false);
  const [showPolicy, setPolicy] = useAsyncSetState(false);
  const [showForgotPassword, setShowForgotPassword] = useAsyncSetState(false);
  const [forgotAlert] = useQueryParam("forgot", StringParam);

  const handleValidLoginSubmit = async (data) => {
    setProcessing(true);
    try {
      const response = await fetch(`${apiUrl}rest.api/login`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({...data}),
      });

      if (response.status === 503) {
        setProcessing(false);
        setLoginError("Something went wrong");
        return;
      }

      const parsedResponse = await response.json();

      if (parsedResponse?.success === "Invalid username/password") {
        setProcessing(false);
        setLoginError("Invalid username and/or password.");
      }

      if (response.status === 401) {
        setProcessing(false);
        setLoginError(parsedResponse.error);
      }

      if (parsedResponse.success && parsedResponse.user && !parsedResponse.jwtToken) {
        await setMfa({user: parsedResponse.user, hasUserTOTP: parsedResponse.hasUserTOTP, loginDetails: data});
        if (parsedResponse.user.mobileNumber) {
          setAllowedOptions(prev => [...prev, "sms"]);
        }

        if (parsedResponse.user.email) {
          setAllowedOptions(prev => [...prev, "email"]);
        }

        if (parsedResponse.hasUserTOTP) {
          setAllowedOptions(prev => [...prev, "user-totp"]);
        }

        setUserData(parsedResponse.user);

        await setShowMfa(true);
        return;
      } else {
        if (parsedResponse.jwtToken) {
          localStorage.setItem("authToken", parsedResponse.jwtToken);
        }

        if (parsedResponse.user.id) {
          setAuthDetails(data);
          if (authUrl && authUrl !== "") {
            formRef.current.submit();
          } else {
            window.location.href = portalUrl;
          }
        }
      }
    } catch (err) {
      console.log("login error", err);
      setProcessing(false);
      setLoginError("Invalid username and/or password.");
    }

    setProcessing(false);
  };

  const handleClickForgotPassword = async (e) => {
    e.preventDefault();
    return setShowForgotPassword(true);
  };

  const onCloseForgotPassword = async () => {
    return setShowForgotPassword(false);
  };

  const togglePassword = () => {
    if (!passwordShow) {
      setPasswordShow(true);
    } else {
      setPasswordShow(false);
    }
  };

  const checkBoxComponent = () => {

    const tickCheckBox = () => {
      setIsChecked((prev) => !prev);
    };

    useEffect(() => {
      const dataType = typeof isChecked;
      if (dataType === "boolean") {
        localStorage.setItem("keepUserLogged", JSON.stringify(isChecked));
      } else {
        window.alert("action not allowed");
      }
    }, [isChecked]);

    return (
      <Col>
        <Form.Check>
          <Form.Check.Input
            type="checkbox"
            className="vw-login-check-remember"
            onChange={() => tickCheckBox()}
            checked={isChecked}
          />
          <Form.Check.Label
            for="login-check-remember"
            className="vw-login-label-remember"
          >
            {"Keep me login"}
          </Form.Check.Label>
        </Form.Check>
      </Col>
    );
  };

  const forgotPasswordComponent = () => {
    return (
      <Col className="vw-forgot-password">
        <a
          className="vw-forgot-password-text"
          onClick={handleClickForgotPassword}
        >
          {"Forgot Password?"}
        </a>
      </Col>
    );
  };

  return (
    <>
      {showTerm && <Terms onClose={() => setTerm(false)} />}
      {showPolicy && <PrivacyPolicy onClose={() => setPolicy(false)} />}
      <form ref={formRef} name="login" action={authUrl} method="post">
        <input type="hidden" value="hotspot" name="radius11" />
        <input type="hidden" name="dst" value={`${dst || portalUrl}`} />
        <input type="hidden" name="username" value={authDetails?.userName} />
        <input type="hidden" name="password" value={authDetails?.password} />
      </form>
      <Formsy
        // className="vw-login-panel"
        onValidSubmit={handleValidLoginSubmit}
        disabled={processing}
      >
        {showForgotPassword &&
        <ForgotPassword onClose={onCloseForgotPassword} />
        }
        <Container fluid>
          <Row>
            <Col xs={12} className="vw-login-field mb-4">
              <TextField
                className="vw-form-field input-radius"
                name="userName"
                autoComplete="off"
                placeholder="Username"
                id="username-field"
                disabled={processing}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="vw-login-field mb-3">
              <TextField
                className="vw-form-field input-radius"
                name="password"
                id="password-field"
                autoComplete="off"
                placeholder="Password"
                type={passwordShow ? "text" : "password"}
                disabled={processing}
                required
              />
              {
                passwordShow ?
                  <i
                    className="fas fa-eye pass-icon"
                    onClick={() => togglePassword()}
                  />
                  :
                  <i
                    className="fas fa-eye-slash pass-icon"
                    onClick={() => togglePassword()}
                  />
              }
            </Col>
          </Row>
          <Row className="vw-login-remember">
            {checkBoxComponent()}
            {forgotPasswordComponent()}
          </Row>
          {loginError && (
            <Row>
              <Col>
                <div className="alert alert-danger login-error">
                  <i
                    className="fas fa-exclamation-circle"
                  />
                  {loginError}
                </div>
              </Col>
            </Row>
          )}
          {forgotAlert === "true" && (
            <Row>
              <Col>
                <div className="alert alert-success mb-0">
                  {"Your password has been changed."}
                  <br />
                  {"Please check your email for your new password"}
                </div>
              </Col>
            </Row>
          )}
          <Row className="vw-login-buttons">
            {props.children}
            <Col
              lg={12}
              xs={12}
              md={12}
              sm={12}
              className="vw-loginbtn"
            >
              <Button
                variant="login"
                className="vw-button"
                type="submit"
                disabled={processing}
              >
                {"Login"}
              </Button>
            </Col>
          </Row>
        </Container>
      </Formsy>
    </>
  );
}
