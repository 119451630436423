import React, {Fragment, useState} from "react";
import {Link} from "gatsby";
import {Col, Row, Container} from "react-bootstrap";
import root from "window-or-global";

import "../../style/menu.scss";
import "../../style/animation.scss";

export default function Header() {
  let user;
  const [openMenu, setOpenMenu] = useState(false);
  const pathname = (root.location || {}).pathname || "";
  // const isWhite = pathname.startsWith("/register") && pathname !== "/register";
  const isWhite = pathname.startsWith("/register") && !(pathname === "/register" || pathname === "/register/");
  function handleCloseMenu() {
    return setOpenMenu(false);
  }
  function handleMenuButtonClick() {
    return setOpenMenu(!openMenu);
  }

  const evergyLogoComponent = () => {
    const isLoginPage = pathname.startsWith("/login") && (pathname === "/login" || pathname === "/login/");
    const isLanding = pathname.startsWith("/");

    if (!isLoginPage && !isLanding) {
      return (
        <div className="col-2">
          <h1 style={{margin: 0}}>
            <a
              href={"https://evergy.com.au"}
              style={{
                color: "black",
                textDecoration: "none",
                marginRight: 40,
              }}
              onClick={handleCloseMenu}>
              <img className={isWhite ? "iswhite colour vw-logo" : "vw-logo colour"} src="https://cdn.vostro.cloud/hotspots/evergy/evergy-logo-mono.svg" />
              <img className={isWhite ? "iswhite mono vw-logo" : "vw-logo mono"} src="https://cdn.vostro.cloud/hotspots/evergy/evergy-logo.svg" />
            </a>
          </h1>
        </div>
      );
    }
  };

  return (
    <div className={`vw-header-container ${openMenu ? " open" : ""} ${isWhite ? "white" : ""}`}>
      <div className={`vw-menu-header ${openMenu ? " open" : ""}`}>
        <Container>
          <Row className="no-gutters">
            <div className="col-12 mx-auto align-self-center">
              <Container className="no-gutters">
                <Row className="align-items-center">
                  {evergyLogoComponent()}
                  <Col className="vw-menu col-auto ml-auto">
                    <ul>
                      <li>
                        <a href="/">{"home"}</a>
                      </li>
                      <li>
                        <a href="https://evergy.com.au/contact-us-internet/">{"contact us"}</a>
                      </li>
                      <li>
                        <a href="https://evergy.com.au/faq/">{"need help?"}</a>
                      </li>
                      {/* <LoginSection
                        showUserMenu={this.state.showUserMenu}
                        toggleShowMenu={this.handleToggleUserMenu}
                        isWhite={isWhite}
                        resetScrollPosition={this.resetScrollPosition}
                      /> */}
                      <li
                        className="d-block d-xl-none vw-menu-button clearfix"
                        onClick={handleMenuButtonClick}
                        style={{
                          height: 80,
                          marginTop: -35,
                          paddingTop: 40,
                          marginLeft: 0,
                          marginRight: 0,
                          paddingLeft: 15,
                          paddingRight: 15,
                        }}>
                        <MenuButton
                          open={openMenu}
                          isWhite={isWhite}
                          override={isWhite}
                        />
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Container>
            </div>
          </Row>
        </Container>
      </div>
      <div>
        {openMenu && (
          <div className="magictime-300ms vw-mobile-menu">
            <Row className="vw-menurow no-gutters">
              <Col xs={12}>
                <a href="/" className="mobile-menu-item" activeClassName="vw-active" onClick={handleCloseMenu}>
                  {"home"}
                </a>
              </Col>
              <Col xs={12}>
                <a href="https://evergy.com.au/contact-us-internet/" className="mobile-menu-item" activeClassName="vw-active" onClick={handleCloseMenu}>
                  {"contact us"}
                </a>
              </Col>
              <Col xs={12}>
                <a href="https://evergy.com.au/faq/" className="mobile-menu-item" activeClassName="vw-active" onClick={handleCloseMenu}>
                  {"need help?"}
                </a>
              </Col>
            </Row>
            <Row className="vw-menurow no-gutters">
              <Col xs={12} onClick={() => (root.location = "/join-us")} >
                <Link to="/join-us" className="mobile-menu-item" activeClassName="vw-active" onClick={handleCloseMenu}>
                  <div>
                    {"join us"}
                  </div>
                </Link>
              </Col>
              <Col xs={12} onClick={() => user ? (root.location = "/portal") : (root.location = "/login")}>
                {(user) ? (
                  <Fragment/>
                  // <Link to="/portal" className="mobile-menu-item" activeClassName="vw-active" onClick={this.handleCloseMenu}>
                  //   <div className="vw-menu-icon" style={{backgroundImage: `url(${UserCircleWhiteIcon})`}}>
                  //     {"Portal"}
                  //   </div>
                  // </Link>
                ) : (
                  <Link to="/login" className="mobile-menu-item" activeClassName="vw-active" onClick={handleCloseMenu}>
                    <div>
                      {"login"}
                    </div>
                  </Link>
                )}
              </Col>
            </Row>
          </div>
        )}
      </div>
    </div>
  );
}

function MenuButton({open, isWhite, override, ...rest}) {
  const tricky = {
    display: "block",
    width: "100%",
    height: 2,
    // background: isWhite ? "rgb(0, 0, 0)" : "rgb(255, 255, 255)",
    borderRadius: 1,
    marginBottom: 5,
    transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
  };
  let span1, span2, span3;
  let backgroundColor;
  if (override) {
    backgroundColor = isWhite ? "black" : "white";
  }
  if (open) {
    span1 = Object.assign({backgroundColor: "white"}, tricky, {
      transform: "translateY(7px) rotate(45deg)",
    });
    span2 = Object.assign({backgroundColor: "white"}, tricky, {
      opacity: 0,
    });
    span3 = Object.assign({backgroundColor: "white"}, tricky, {
      transform: "translateY(-7px) rotate(-45deg)",
      marginBottom: 0,
    });
  } else {
    span1 = Object.assign({backgroundColor}, tricky);
    span2 = Object.assign({backgroundColor}, tricky);
    span3 = Object.assign({backgroundColor}, tricky, {marginBottom: 0});
  }

  return (<div style={{width: 24}} {...rest}>
    <span className={`vw-menu-button-line ${isWhite ? "black" : "white"}`} style={span1} />
    <span className={`vw-menu-button-line ${isWhite ? "black" : "white"}`} style={span2}/>
    <span className={`vw-menu-button-line ${isWhite ? "black" : "white"}`} style={span3} />
  </div>);
}
