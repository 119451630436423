import React, {useEffect, useState} from "react";
import {Col, Container, Modal, Row} from "react-bootstrap";
import {Link} from "gatsby";

import Main from "../main";
import Header from "../section/header";
import Footer from "../section/footer";
import LoginControl from "../../controls/login";
import MfaPanel from "./mfa";


const LoginPage = () => {
  const [showMfa, setShowMfa] = useState(false);
  const [mfaMethod, setMfaMethod] = useState(null);
  const [allowedOptions, setAllowedOptions] = useState(["admin-generated-otp"]);
  const [userData, setUserData] = useState(null);
  const [mfa, setMfa] = useState(null);

  useEffect(() => {
    if (userData) {
      setShowMfa(false);
    }
  }, []);


  return (
    <Main>
      <Header />
      <div className="vw-login-page">
        {location?.search?.indexOf("EOVERLIMIT") > -1 ? (
          <Row
            className="vw-alert red no-gutters"
            onClick={() => (window.location.href = `${process.env.PORTAL_URL}/`)}
          >
            <Col>
              {
                "You have reached the data limit associated with your plan. Click here to assign a new plan."
              }
            </Col>
          </Row>
        ) : (
          undefined
        )}
        <div
          className="vw-landing section-1"
        >
          <div className="vw-landing-bg-elements">
            <img
              className="vw-landing-section-sun"
              src="https://cdn.vostro.cloud/hotspots/evergy/sun.svg"
            />

            <img
              className="vw-landing-section-cloud-1"
              src="https://cdn.vostro.cloud/hotspots/evergy/clouds.svg"
            />

            <img
              className="vw-landing-section-cloud-2"
              src="https://cdn.vostro.cloud/hotspots/evergy/clouds.svg"
            />

            <img
              className="vw-landing-section-cloud-3"
              src="https://cdn.vostro.cloud/hotspots/evergy/clouds.svg"
            />

            <img
              className="vw-landing-section-cloud-4"
              src="https://cdn.vostro.cloud/hotspots/evergy/clouds.svg"
            />
          </div>
          <div>
            <Container>
              <Row className="align-items-center vw-fs">
                <Col xs={12} lg={7}>
                  <Row>
                    <Col md={12} lg={5} className="section-logo-container">
                      <img
                        className="vw-landing-section-1-logo"
                        src="https://cdn.vostro.cloud/hotspots/evergy/evergy-logo.svg"
                      />
                    </Col>
                    <Col md={12} lg={7}>
                      <div
                        className="vw-eligible-panel"
                        style={{marginTop: 30, marginBottom: 30}}
                      >
                        <Container fluid>
                          <Row>
                            <Col xs={12} className="vw-title">
                              {"Affordable and Reliable"}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} className="vw-subtitle">
                              {
                                "We know you might not find energy and utility services as exciting as we do and that’s why we offer you an affordable, sustainable and easy to use service for all your energy, hot water, air-conditioning and internet needs."
                              }
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} className="vw-btn">
                              <Link
                                className="btn-outline-white vw-button"
                                to="/join-us"
                              >
                                {"View Offers"}
                              </Link>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} lg={5}>
                  <Modal
                    show={showMfa && userData}
                    className="login-mfa-panel-modal"
                  >
                    <MfaPanel
                      mfaMethod={mfaMethod}
                      setShowMfa={setShowMfa}
                      mfa={mfa}
                      allowedOptions={allowedOptions}
                      setMfaMethod={setMfaMethod}
                      userData={userData}
                      setUserData={setUserData}
                    />
                  </Modal>
                  {!showMfa && <Container>
                    <div className="vw-login-panel mb-5">
                      <Row>
                        <Col xs={12} className="vw-title">
                          {"Login"}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} className="vw-subtitle">
                          {"Evergy Internet Portal"}
                        </Col>
                      </Row>
                      <LoginControl
                        authUrl={process.env.AUTH_URL}
                        portalUrl={process.env.PORTAL_URL}
                        apiUrl={process.env.BACKEND_URL}
                        setMfa={setMfa}
                        setShowMfa={setShowMfa}
                        setAllowedOptions={setAllowedOptions}
                        setUserData={setUserData}

                      />
                    </div>
                  </Container>}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Footer colour="black" />
      </div>
    </Main>
  );
};

export default LoginPage;
